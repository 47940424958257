import React, { Component } from 'react'
import { Route, Switch, withRouter } from 'react-router-dom'
import asyncComponent from 'asyncComponent'
import ProtectedRoute from 'ProtectedRoute'

const About = asyncComponent(() => import('pages/About'))
const ArtistCreate = asyncComponent(() => import('pages/ArtistCreate'))
const ArtistProfile = asyncComponent(() => import('pages/Profile/ArtistProfile'))
const Artists = asyncComponent(() => import('pages/Artists'))
const BuyTickets = asyncComponent(() => import('pages/BuyTickets'))
const DeleteModal = asyncComponent(() => import('components/DeleteModal'))
const EventCreate = asyncComponent(() => import('pages/EventCreate'))
const EventProfile = asyncComponent(() => import('pages/Profile/EventProfile'))
const EventUpdate = asyncComponent(() => import('pages/EventUpdate'))
const Events = asyncComponent(() => import('pages/Profile/Events'))
const FlagModal = asyncComponent(() => import('components/FlagModal'))
const Footer = asyncComponent(() => import('components/Footer'))
const Header = asyncComponent(() => import('components/Header'))
const Home = asyncComponent(() => import('pages/Home'))
const InviteGuestsModal = asyncComponent(() => import('components/InviteGuestsModal'))
const InvitePreviewModal = asyncComponent(() => import('components/InvitePreviewModal'))
const LandingPage = asyncComponent(() => import('pages/LandingPage'))
const Login = asyncComponent(() => import('pages/Login'))
const LoginModal = asyncComponent(() => import('components/LoginModal'))
const Logout = asyncComponent(() => import('pages/Logout'))
const PasswordResetRequest = asyncComponent(() => import('pages/PasswordResetRequest'))
const PhotoUploadModal = asyncComponent(() => import('components/PhotoUploadModal'))
// const PlayerControls = asyncComponent(() => import('components/PlayerControls'))
const Post = asyncComponent(() => import('pages/Post'))
const PostModal = asyncComponent(() => import('components/PostModal'))
const Posts = asyncComponent(() => import('pages/Posts'))
const SendReminderConfirmModal = asyncComponent(() => import('components/SendReminderConfirmModal'))
const Settings = asyncComponent(() => import('pages/Settings'))
const ShareModal = asyncComponent(() => import('components/ShareModal'))
const Signup = asyncComponent(() => import('pages/Signup'))
const SignupModal = asyncComponent(() => import('components/SignupModal'))
const Terms = asyncComponent(() => import('pages/Terms'))
const Waiver = asyncComponent(() => import('pages/Waiver'))
const UserProfile = asyncComponent(() => import('pages/Profile/UserProfile'))
const VenueCreate = asyncComponent(() => import('pages/VenueCreate'))
const VenueProfile = asyncComponent(() => import('pages/Profile/VenueProfile'))

class Routes extends Component {
    render() {
        return (
            <div id="content-body" className="content-body">
                <div className="content-wrap">
                    <Header />
                    <Switch>
                        <Route path="/" exact component={LandingPage} />
                        <Route path="/home" exact component={Home} />
                        <Route path="/about" exact component={About} />
                        <Route path="/login" exact component={Login} />
                        <Route path="/logout" exact component={Logout} />
                        <Route path="/settings/:id" exact component={Settings} />
                        <Route path="/signup" exact component={Signup} />
                        <Route path="/create/artist" exact component={ArtistCreate} />
                        <Route path="/user/:id" exact component={UserProfile} />
                        <Route path="/create/event" exact component={EventCreate} />
                        <Route path="/events" exact component={Events} />
                        <Route path="/event/:id" exact component={EventProfile} />
                        <Route path="/event/update/:id" exact component={EventUpdate} />
                        <ProtectedRoute path="/event/:id/tickets" exact component={BuyTickets} />
                        <Route path="/artist/:id" exact component={ArtistProfile} />
                        <Route path="/artist/update/:id" exact component={ArtistCreate} />
                        <Route path="/password-reset-request" exact component={PasswordResetRequest} />
                        <Route path="/posts" exact component={Posts} />
                        <Route path="/post/:id" exact component={Post} />
                        <Route path="/artists" exact component={Artists} />
                        <Route path="/terms" exact component={Terms} />
                        <Route path="/waiver" exact component={Waiver} />
                        <Route path="/create/venue" exact component={VenueCreate} />
                        <Route path="/venue/:id" exact component={VenueProfile} />
                    </Switch>
                    <DeleteModal />
                    <FlagModal />
                    <InviteGuestsModal />
                    <InvitePreviewModal />
                    <SendReminderConfirmModal />
                    <LoginModal />
                    <PhotoUploadModal />
                    <PostModal />
                    <ShareModal />
                    <SignupModal />
                    {/* <PlayerControls /> */}
                </div>
                <Footer />
            </div>
        )
    }
}

export default withRouter(Routes)
