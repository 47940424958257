import React, { Component } from 'react'
import { handleErrorMessage } from 'utils/errorMessage'
import api from 'utils/api'

const PostContext = React.createContext()
const { Provider, Consumer } = PostContext

class PostProvider extends Component {
    state = {
        odesliLinks: {},
    }

    componentDidMount() {}

    getOdesliLinks = async (trackId) => {
        try {
            const odesliResponse = await api.get(`/posts/odesli/${trackId}`)
            return odesliResponse
        } catch (e) {
            handleErrorMessage(e)
            throw e
        }
    }

    getAllPosts = async ({ limit = 4, page = 0, type = '' }) => {
        try {
            const getAllPostsResponse = await api.get('/posts', {
                page: page + 1,
                limit: limit,
                type: type,
            })
            return getAllPostsResponse
        } catch (e) {
            handleErrorMessage(e)
            throw e
        }
    }

    updateState = (state, cb) => {
        this.setState(state, cb)
    }

    render() {
        return (
            <Provider
                value={{
                    ...this.state,
                    getAllPosts: this.getAllPosts,
                    getOdesliLinks: this.getOdesliLinks,
                    updateState: this.updateState,
                }}
            >
                {this.props.children}
            </Provider>
        )
    }
}

export { PostProvider, Consumer as AppConsumer }

export default PostContext
