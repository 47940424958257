import React, { useContext, useEffect, useState } from 'react'
import { Route, Redirect } from 'react-router-dom'
import AuthContext from 'contexts/AuthContext'

const ProtectedRoute = ({ component: Component, ...props }) => {
    const [data, setData] = useState({})
    const { getCurrent } = useContext(AuthContext)

    // check if user is authenticated
    useEffect(() => {
        const fetchData = async () => {
            const authResponse = await getCurrent()
            setData(authResponse)
        }
        fetchData()
    }, [getCurrent])

    return (
        <Route
            {...props}
            render={(props) => {
                if (Object.keys(data).length) {
                    if (data.id) {
                        return <Component {...props} />
                    } else if (data?.message === 'Invalid Token') {
                        // don't know if this condition is too brittle
                        return <Redirect to="/signup" />
                    }
                } else {
                    // not sure if this ever really renders
                    return <div>LOADING</div>
                }
            }}
        />
    )
}

export default ProtectedRoute
