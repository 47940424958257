import React, { Component } from 'react'
// import api from 'utils/api'

const AppContext = React.createContext()
const { Provider, Consumer } = AppContext

class AppProvider extends Component {
    state = {
        deleteModal: {
            visible: false,
        },
        flagModal: {
            visible: false,
        },
        inviteGuestsModal: {
            visible: false,
        },
        invitePreviewModal: {
            visible: false,
        },
        loginModal: {
            visible: false,
        },
        photoUploadModal: {
            visible: false,
        },
        postModal: {
            visible: false,
        },
        sendReminderConfirmModal: {
            visible: false,
        },
        shareModal: {
            visible: false,
        },
        signupModal: {
            visible: false,
            step: 1,
        },
    }

    componentDidMount() {}

    updateState = (state) => {
        this.setState(state)
    }

    render() {
        return (
            <Provider
                value={{
                    ...this.state,
                    updateState: this.updateState,
                }}
            >
                {this.props.children}
            </Provider>
        )
    }
}

export { AppProvider, Consumer as AppConsumer }

export default AppContext
