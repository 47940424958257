import React, { Component } from 'react'
import api from 'utils/api'
import { handleErrorMessage } from 'utils/errorMessage'
import { getToken, setToken, removeToken } from 'utils/token'
import { notification } from 'antd'
import { firebase } from 'utils/firebase'
import 'firebase/auth'
import 'firebase/storage'
import { NOTIFICATION_TOP } from '../constants/layout'
// import { resizePic } from 'utils/resizer'

const AuthContext = React.createContext()
const { Provider, Consumer } = AuthContext

class AuthProvider extends Component {
    state = {
        deactivated: false,
        email: '',
        eventTickets: [],
        firstName: '',
        lastName: '',
        userId: '',
        artistsFollowed: [],
        spotifyAccessToken: '',
        userImageUrl: '',
        isLoadingUser: false,
        location: '',
        university: '',
        tags: [],
    }

    componentWillMount() {
        this.setState({ isLoadingUser: true })
        firebase.auth().onAuthStateChanged(async (user) => {
            if (user) {
                setToken(await user.getIdToken(true))
                this.refreshSession()
            } else {
                removeToken()
                this.setState({ isLoadingUser: false })
            }
        })
    }

    deleteUser = async (userId, history) => {
        try {
            const deleteResponse = await api.delete(`/users/${userId}`, null, true)
            this.setState({
                deactivated: true,
                email: '',
                eventTickets: [],
                firstName: '',
                lastName: '',
                userId: '',
                artists: [],
                artistsFollowed: [],
                location: '',
                university: '',
                tags: [],
            })
            history.push('/')
            notification['success']({
                message: `You have successfully deleted your account.`,
                top: NOTIFICATION_TOP,
            })
            return deleteResponse
        } catch (e) {
            handleErrorMessage(e)
            throw e
        }
    }

    checkUserEmailTaken = async (email) => {
        try {
            const signInMethodsForEmail = await firebase.auth().fetchSignInMethodsForEmail(email)
            if (signInMethodsForEmail.length) {
                // ['password']
                notification['error']({
                    message: 'This email is already in use. Did you mean to log in?',
                    top: NOTIFICATION_TOP,
                })
                return true
            }
            return false
        } catch (e) {
            handleErrorMessage(e)
            throw e
        }
    }

    requestPasswordReset = async ({ email }) => {
        try {
            const requestPasswordResetResponse = await firebase
                .auth()
                .sendPasswordResetEmail(decodeURIComponent(email))
            return requestPasswordResetResponse
        } catch (e) {
            handleErrorMessage(e)
            throw e
        }
    }

    signup = async (accountData) => {
        try {
            const createResponse = await firebase
                .auth()
                .createUserWithEmailAndPassword(accountData.email, accountData.password)
            if (createResponse) {
                const uid = createResponse.user.uid
                const token = await firebase.auth().currentUser.getIdToken(true)
                setToken(token)
                const userData = await api.post('/users/create', {
                    deactivated: false,
                    email: accountData.email,
                    eventTickets: [],
                    firstName: accountData.firstName,
                    lastName: accountData.lastName,
                    id: uid,
                    location: accountData.location,
                    university: accountData.university,
                    tags: accountData.tags,
                })
                if (userData.success) {
                    // Send email verification
                    // await user.sendEmailVerification()
                    notification['success']({
                        message: 'You have successfully created an account and logged in.',
                        top: NOTIFICATION_TOP,
                        duration: 100,
                    })
                    this.setState({
                        deactivated: false,
                        email: createResponse.user.email,
                        eventTickets: [],
                        userId: createResponse.user.uid,
                        firstName: accountData.firstName,
                        lastName: accountData.lastName,
                        artistsFollowed: [],
                        location: accountData.location,
                        university: accountData.university,
                        tags: accountData.tags,
                    })
                } else {
                    let responseError = new Error(userData.message)
                    throw responseError
                }
            } else {
                let responseError = createResponse
                throw responseError
            }
        } catch (e) {
            handleErrorMessage(e)
            throw e
        }
    }

    login = async (credentials) => {
        try {
            const authenticateResponse = await firebase
                .auth()
                .signInWithEmailAndPassword(credentials.email, credentials.password)

            if (authenticateResponse) {
                const uid = firebase.auth().currentUser.uid
                const token = await firebase.auth().currentUser.getIdToken(true)
                // Email Verification Check
                // const emailVerified = firebase.auth().currentUser.emailVerified;
                // if (!emailVerified) {
                //     notification['error']({
                //         message: 'Email not verified. Please verify your email before logging in.',
                //         top: NOTIFICATION_TOP,
                //     })
                //     return;
                // }
                setToken(token)
                const user = await api.get(`/users/${uid}`)

                const {
                    artists,
                    artistsFollowed,
                    email,
                    eventTickets,
                    firstName,
                    lastName,
                    location,
                    university,
                    tags,
                    message,
                    id,
                    success,
                } = user

                if (success) {
                    this.setState({
                        deactivated: false,
                        artists,
                        artistsFollowed,
                        email,
                        eventTickets,
                        firstName,
                        lastName,
                        userId: id,
                        location,
                        university,
                        tags,
                    })
                } else {
                    throw new Error(message)
                }
            }
        } catch (e) {
            handleErrorMessage(e)
            throw e
        }
    }

    logout = () => {
        firebase
            .auth()
            .signOut()
            .then(() => {
                removeToken()
                this.setState({
                    deactivated: false,
                    email: '',
                    eventTickets: [],
                    firstName: '',
                    lastName: '',
                    userId: '',
                    artists: [],
                    artistsFollowed: [],
                    location: '',
                    university: '',
                    tags: [],
                })
                notification['success']({
                    message: 'You have successfully logged out of your account.',
                    top: NOTIFICATION_TOP,
                })
            })
            .catch((e) => {
                handleErrorMessage(e)
                throw e
            })
    }

    uploadPhoto = async ({ file, id }) => {
        try {
            const storage = firebase.storage()
            const storageRef = storage.ref()
            const pic = storageRef.child(`images/${id}/profilePic.jpg`)
            // const image = await resizePic(file)

            await pic.putString(file, 'data_url')
            // await pic.put(file, imageData)

            const downloadURL = await pic.getDownloadURL()
            // this.setState({
            //     userImageUrl: downloadURL,
            // })

            // notification['success']({
            //     message: 'You have successfully updated your profile image.',
            //     top: NOTIFICATION_TOP,
            // })

            return downloadURL
        } catch (e) {
            handleErrorMessage(e)
            throw e
        }
    }

    getPhotoUrl = async ({ id, urlFragment }) => {
        try {
            const storage = firebase.storage()
            const dirStorageRef = storage.ref(`images/${id}`)
            const list = await dirStorageRef.listAll()
            // check if there are any items in the dir before GETting, to prevent 404
            if (list.items.length > 0) {
                const storageRef = storage.ref(`images/${id}/${urlFragment}.jpg`)
                const downloadUrl = await storageRef.getDownloadURL()
                return downloadUrl
            }
            return ''
        } catch (e) {
            handleErrorMessage(e)
            throw e
        }
    }

    refreshSession = async () => {
        const token = getToken()
        if (token) {
            try {
                const getCurrentResponse = await api.get('/users/current')
                const {
                    email,
                    eventTickets,
                    firstName,
                    lastName,
                    id,
                    artists,
                    artistsFollowed,
                    location,
                    university,
                    tags,
                } = getCurrentResponse
                this.setState({
                    deactivated: false,
                    email,
                    eventTickets: eventTickets || [],
                    firstName,
                    lastName,
                    userId: id,
                    artists,
                    artistsFollowed,
                    isLoadingUser: false,
                    location,
                    university,
                    tags,
                })
            } catch (e) {
                // I'm not sure we want to show this error message because sometimes it is just the string "Not Found"
                // handleErrorMessage(e)
                throw e
            }
        }
    }

    getCurrent = async () => {
        try {
            const response = await api.get(`/users/current`)
            this.setState({ ...response })
            return response
        } catch (e) {
            handleErrorMessage(e)
            throw e
        }
    }

    getUser = async (userId) => {
        try {
            const getUserResponse = await api.get(`/users/${userId}`)
            this.setState({ ...getUserResponse })
            return getUserResponse
        } catch (e) {
            handleErrorMessage(e)
            throw e
        }
    }

    getUserByEmail = async (email) => {
        try {
            const getUserByEmailResponse = await api.get(`/users/email`, {
                email,
            })
            return getUserByEmailResponse
        } catch (e) {
            handleErrorMessage(e)
            throw e
        }
    }

    updateArtistsFollowed = async (userId, userParam, artistName) => {
        try {
            const updateArtistsFollowedResponse = await api.put(`/users/${userId}`, userParam, true)
            let newArtistsFollowed = this.state.artistsFollowed
            // if artist is notFollowed we want to push it to the list and start following
            if (!userParam.newArtist.isFollowed) {
                newArtistsFollowed.push(userParam.newArtist.id)
                // otherwise, filter this artist out of the list.
            } else {
                newArtistsFollowed = this.state.artistsFollowed.filter(
                    (artistId) => userParam.newArtist.id !== artistId
                )
            }
            this.setState({
                artistsFollowed: newArtistsFollowed,
            })
            notification['success']({
                message: `You have successfully ${
                    userParam.newArtist.isFollowed ? 'unfollowed' : 'followed'
                } ${artistName}`,
                top: NOTIFICATION_TOP,
            })
            return updateArtistsFollowedResponse
        } catch (e) {
            handleErrorMessage(e)
            throw e
        }
    }

    // for email, password, firstName, lastName, uni, loc, tags... for artistsFollowed or photo see above
    updateUser = async (userId, userParam) => {
        try {
            const updateUserResponse = await api.put(`/users/${userId}`, userParam, true)

            const _getNewTickets = () => {
                let index = this.state.eventTickets.findIndex(
                    (el) => el.id === userParam.eventTickets[0].id
                )
                if (index === -1) {
                    if (this.state.eventTickets) {
                        return [...this.state.eventTickets, ...userParam.eventTickets]
                    }
                    return userParam.eventTickets
                }
                return this.state.eventTickets
            }

            let newState = this.state
            for (const [key, value] of Object.entries(userParam)) {
                if (key === 'eventTickets') {
                    newState.eventTickets = _getNewTickets()
                } else {
                    newState[key] = value
                }
            }

            this.setState(newState)
            let notificationMessage = ''
            if (userParam.deactivated === true) {
                notificationMessage = 'You have successfully deactivated your account'
                notification['success']({
                    message: notificationMessage,
                    top: NOTIFICATION_TOP,
                })
            } else if (userParam.deactivated === false) {
                notificationMessage =
                    'You have successfully reactivated your account. Reactivate any artists you manage from your profile.'
                notification['success']({
                    message: notificationMessage,
                    top: NOTIFICATION_TOP,
                })
            } else if (userParam.eventTickets) {
            } else {
                notificationMessage = 'You have successfully updated your information.'
                notification['success']({
                    message: notificationMessage,
                    top: NOTIFICATION_TOP,
                })
            }
            return updateUserResponse
        } catch (e) {
            handleErrorMessage(e)
            throw e
        }
    }

    updateState = (state) => {
        this.setState(state)
    }

    render() {
        return (
            <Provider
                value={{
                    ...this.state,
                    checkUserEmailTaken: this.checkUserEmailTaken,
                    deleteUser: this.deleteUser,
                    getCurrent: this.getCurrent,
                    getPhotoUrl: this.getPhotoUrl,
                    getUser: this.getUser,
                    getUserByEmail: this.getUserByEmail,
                    login: this.login,
                    logout: this.logout,
                    requestPasswordReset: this.requestPasswordReset,
                    signup: this.signup,
                    updateArtistsFollowed: this.updateArtistsFollowed,
                    updateState: this.updateState,
                    updateUser: this.updateUser,
                    uploadPhoto: this.uploadPhoto,
                }}
            >
                {this.props.children}
            </Provider>
        )
    }
}

export { AuthProvider, Consumer as AuthConsumer }

export default AuthContext
