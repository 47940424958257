import firebase from 'firebase/app'
import { isLotusDev } from './env'

const firebaseProductionConfig = {
    apiKey: 'AIzaSyCp2TRqXcFckzNUiYpsNFPpO9LWQKN3TEg',
    authDomain: 'curio-5ddc0.firebaseapp.com',
    databaseURL: 'https://curio-5ddc0-default-rtdb.firebaseio.com',
    projectId: 'curio-5ddc0',
    storageBucket: 'curio-5ddc0.appspot.com',
    messagingSenderId: '726874453770',
    appId: '1:726874453770:web:74beaa6f81784136e9ecf3',
    measurementId: 'G-3YPN0MKGMG',
}

const firebaseDevelopmentConfig = {
    apiKey: "AIzaSyCTMGfDaW1-Vca9NBCXOs9BmYdKafo4Hlg",
    authDomain: "curio-development-9ed79.firebaseapp.com",
    databaseURL: 'https://curio-development-9ed79-default-rtdb.firebaseio.com',
    projectId: "curio-development-9ed79",
    storageBucket: "curio-development-9ed79.appspot.com",
    messagingSenderId: "455432243541",
    appId: "1:455432243541:web:01a1c3ebd4cb579af447dc"
}

const config = isLotusDev ? firebaseDevelopmentConfig : firebaseProductionConfig

console.log(isLotusDev)
firebase.initializeApp(config)

const checkoutButton = document.getElementById('get-tickets-button')

checkoutButton &&
    checkoutButton.addEventListener('click', () => {
        console.log('CLICKED')
    })

export { firebase }