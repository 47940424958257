import React from 'react'
import PropTypes from 'prop-types'
import { Spin } from 'antd'
import { LoadingOutlined } from '@ant-design/icons'

const antIcon = <LoadingOutlined style={{ fontSize: '2rem', color: 'white' }} spin />

const CircularProgress = (props) => {
    return (
        <div className="circular-loading">
            <Spin indicator={antIcon} />
        </div>
    )
}

CircularProgress.propTypes = {
    size: PropTypes.number,
    fullPage: PropTypes.bool,
}

CircularProgress.defaultProps = {
    fullPage: true,
}

export default CircularProgress
