import React, { Component } from 'react'
import { notification } from 'antd'
import api from 'utils/api'
import { handleErrorMessage } from 'utils/errorMessage'
import { NOTIFICATION_TOP } from '../constants/layout'

const ArtistContext = React.createContext()
const { Provider, Consumer } = ArtistContext

class ArtistProvider extends Component {
    state = {
        artistAvatar: '',
        artistName: '',
        biography: '',
        deactivated: false,
        links: {
            website: '',
            soundcloud: '',
            spotify: '',
            facebook: '',
            twitter: '',
            instagram: '',
            bandsintown: '',
            youTube: '',
        },
        tags: '',
        location: {
            city: '',
            state: '',
            zipCode: null,
        },
    }

    componentDidMount() {}

    createArtist = async (artistData) => {
        try {
            const createResponse = await api.post('/artists/create', artistData, true)
            if (createResponse.success) {
                notification['success']({
                    message: `You have successfully added ${artistData.artistName}`,
                    top: NOTIFICATION_TOP,
                })
                return createResponse
            } else {
                const e = new Error(createResponse.message)
                throw e
            }
        } catch (e) {
            handleErrorMessage(e)
            throw e
        }
    }

    deleteArtist = async (artistId, history) => {
        try {
            const deleteResponse = await api.delete(`/artists/${artistId}`, null, true)
            this.setState(this.state)
            history.push('/events')
            notification['success']({
                message: `You have successfully deleted the artist.`,
                top: NOTIFICATION_TOP,
            })
            return deleteResponse
        } catch (e) {
            handleErrorMessage(e)
            throw e
        }
    }

    updateArtist = async (artistId, artistData) => {
        try {
            const updateResponse = await api.put(`/artists/${artistId}`, artistData, true)
            notification['success']({
                message: `You have successfully updated ${updateResponse.artistName}`,
                top: NOTIFICATION_TOP,
            })
            return updateResponse
        } catch (e) {
            handleErrorMessage(e)
            throw e
        }
    }

    getArtist = async (artistId) => {
        try {
            const getArtistResponse = await api.get(`/artists/${artistId}`)
            return getArtistResponse
        } catch (e) {
            handleErrorMessage(e)
            throw e
        }
    }

    getArtists = async ({ filter }) => {
        try {
            const getArtistsResponse = await api.get(`/artists${filter && `?type=${filter}`}`)
            return getArtistsResponse
        } catch (e) {
            handleErrorMessage(e)
            throw e
        }
    }

    getTrackChoices = async (query) => {
        try {
            const getTrackChoicesResponse = await api.get(
                `/posts/tracks/${encodeURIComponent(query)}`
            )
            return getTrackChoicesResponse
        } catch (e) {
            handleErrorMessage(e)
            throw e
        }
    }

    getPost = async (postId) => {
        try {
            const getPostResponse = await api.get(`/posts/${postId}`)
            return getPostResponse
        } catch (e) {
            handleErrorMessage(e)
            throw e
        }
    }

    getAllPosts = async () => {
        try {
            const getAllPostsResponse = await api.get('/posts')
            return getAllPostsResponse
        } catch (e) {
            handleErrorMessage(e)
            throw e
        }
    }

    // This really just gets top tracks info from spotify. it is poorly named.
    getPosts = async (spotifyAccessToken) => {
        try {
            const getPostsResponse = await api.get('/artists/posts', {
                spotifyAccessToken,
            })
            return getPostsResponse
        } catch (e) {
            handleErrorMessage(e)
            throw e
        }
    }

    createPost = async (postData) => {
        try {
            const createResponse = await api.post('/posts/create', postData, true)
            if (createResponse.success) {
                notification['success']({
                    message: `You have successfully created your post`,
                    top: NOTIFICATION_TOP,
                })
                return createResponse
            } else {
                const e = new Error(createResponse.message)
                throw e
            }
        } catch (e) {
            handleErrorMessage(e)
            throw e
        }
    }

    updatePost = async (postData) => {
        try {
            const updateResponse = await api.put(`/posts/${postData.id}`, postData, true)
            if ('flagReason' in postData) {
                notification['success']({
                    message: 'You have successfully reported this post',
                    top: NOTIFICATION_TOP,
                })
            } else if (!('like' in postData || 'unlike' in postData)) {
                notification['success']({
                    message: `You have successfully updated a post`,
                    top: NOTIFICATION_TOP,
                })
            }

            return updateResponse
        } catch (e) {
            handleErrorMessage(e)
            throw e
        }
    }

    deletePost = async (postId) => {
        try {
            const deleteResponse = await api.delete(`/posts/${postId}`, null, true)
            notification['success']({
                message: `You have successfully deleted your post`,
                top: NOTIFICATION_TOP,
            })
            return deleteResponse
        } catch (e) {
            handleErrorMessage(e)
            throw e
        }
    }

    updateState = (state) => {
        this.setState(state)
    }

    render() {
        return (
            <Provider
                value={{
                    ...this.state,
                    createArtist: this.createArtist,
                    createPost: this.createPost,
                    deleteArtist: this.deleteArtist,
                    deletePost: this.deletePost,
                    getAllPosts: this.getAllPosts,
                    getArtist: this.getArtist,
                    getArtists: this.getArtists,
                    getPost: this.getPost,
                    getPosts: this.getPosts,
                    getTrackChoices: this.getTrackChoices,
                    updateArtist: this.updateArtist,
                    updatePost: this.updatePost,
                    updateState: this.updateState,
                }}
            >
                {this.props.children}
            </Provider>
        )
    }
}

export { ArtistProvider, Consumer as ArtistConsumer }

export default ArtistContext
