import { notification } from 'antd'
import { NOTIFICATION_TOP } from '../constants/layout'

export const extractErrorMessage = function (e) {
    const message = e.error_description || e.message || ''
    const match = message.match(/.+[A-Z_]+:(.+)/)

    if (match && match.length > 1) {
        return match[1]
    }

    return message
}

export const handleErrorMessage = (e) => {
    if (e.name && e.name === 'FirebaseError' && e.code === 'storage/object-not-found') {
        return
    }
    notification['error']({
        message: extractErrorMessage(e),
        top: NOTIFICATION_TOP,
    })
}
