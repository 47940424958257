import React from 'react'
import Routes from './Routes'
import './custom.css'
import { PayPalScriptProvider } from '@paypal/react-paypal-js'

const initialOptions = {
    // live
    clientId: 'AaARqX3NkckWaWMjDhyuYpJMGRf-23T4Kr6jG8KF30Jl1VogTkDQ0vqvBSiEHz-nG5m3QnY00nPQegTF', 
    // test
    // clientId: 'AYXYemV43de09NzhyYmEFjVlkzmjLzKpHY07n0l4eEkJFA6cAx5erZcWFeF6JPF44dQaxwC9PURiFRfY', 
    currency: "USD",
    enableFunding: 'venmo',
    intent: "capture",
}

function App() {
    return <PayPalScriptProvider options={initialOptions}><Routes /></PayPalScriptProvider>
}

export default App
