import React from 'react'
import ReactDOM from 'react-dom'
import { Router } from 'react-router-dom'
import history from './browserHistory'
import App from './App'
import * as serviceWorker from './serviceWorker'
import { AuthProvider } from './contexts/AuthContext'
import { AppProvider } from 'contexts/AppContext'
import { HomeProvider } from 'contexts/HomeContext'
import { PlayerProvider } from 'contexts/PlayerContext'
import { EventProvider } from 'contexts/EventContext'
import { ArtistProvider } from './contexts/ArtistContext'
import { PostProvider } from 'contexts/PostContext'
import { VenueProvider } from 'contexts/VenueContext'
import ScrollToTop from 'components/ScrollToTop'
import HelmentMetaData from 'components/HelmetMetaData'

import 'antd/dist/antd.css'

// import {
//     setSpotifyAccess,
//     setSpotifyAccessToken,
//     setSpotifyTokenExpirationTime,
// } from './utils/localStorageData'
// import { SpotifyAccess } from './constants/SpotifyAccess'
// import { decodeHashParams } from './utils/url'

require('./utils/polyfill')

// const hashStr = window.location.hash // everything in address after #, here spotify puts successfull auth tokens
// const searchStr = window.location.search // everything in address after ?, here spotify puts access denials
// const hashParams = decodeHashParams(hashStr.slice(1, hashStr.length))
// const searchParams = decodeHashParams(searchStr.slice(1, searchStr.length))

// if (hashParams.access_token) {
//     setSpotifyAccess(SpotifyAccess.ALLOWED)
//     setSpotifyAccessToken(hashParams.access_token)
//     setSpotifyTokenExpirationTime(hashParams.expires_in)
//     window.close()
// } else if (searchParams.error) {
//     setSpotifyAccess(SpotifyAccess.DENIED)
//     window.close()
// } else {
ReactDOM.render(
    <Router history={history}>
        <HelmentMetaData />
        <AuthProvider>
            <AppProvider>
                <HomeProvider>
                    <PlayerProvider>
                        <ArtistProvider>
                            <EventProvider>
                                <PostProvider>
                                    <VenueProvider>
                                        <ScrollToTop>
                                            <App />
                                        </ScrollToTop>
                                    </VenueProvider>
                                </PostProvider>
                            </EventProvider>
                        </ArtistProvider>
                    </PlayerProvider>
                </HomeProvider>
            </AppProvider>
        </AuthProvider>
    </Router>,
    document.getElementById('root')
)
// }

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
