import React, { Component } from 'react'
import { notification } from 'antd'
import api from 'utils/api'
import { handleErrorMessage } from 'utils/errorMessage'
import { NOTIFICATION_TOP } from '../constants/layout'
const EventContext = React.createContext()
const { Provider, Consumer } = EventContext

class EventProvider extends Component {
    state = {
        marquee: '',
        description: '',
        artists: [],
        date: null,
        price: null,
        roughSize: '',
        maxTickets: null,
        eventAvatar: '',
        venue: '',
        showTypes: [],
        showVolumes: [],
        tickets: [],
        ticketsSold: null,
    }

    componentDidMount() { }

    cancelEvent = async ({ eventId, eventParam }) => {
        let updateEventResponse = {}
        try {
            updateEventResponse = await api.put(`/events/${eventId}`, eventParam, true)

            let newState = this.state

            notification['success']({
                message: `You have successfully cancelled your event.`,
                top: NOTIFICATION_TOP,
            })

            this.setState(newState)
            window.location.reload(false)
        } catch (e) {
            handleErrorMessage(e)
            throw e
        }

        if (updateEventResponse.success === true) {
            try {
                const sendCancellationEmailResponse = await api.post(
                    `/events/send-cancellation`,
                    {
                        eventId,
                    },
                    true
                )
                return sendCancellationEmailResponse
            } catch (e) {
                handleErrorMessage(e)
                throw e
            }
        }
        return {}
    }

    createEvent = async (eventData) => {
        try {
            const stream = await this.getStreamKey()
            const { stream_key, playback_ids, id } = stream.data
            const createResponse = await api.post(
                '/events/create',
                {
                    ...eventData,
                    stream: {
                        stream_key,
                        playback_ids,
                        id,
                    },
                },
                true
            )
            notification['success']({
                message: 'You have successfully scheduled your event',
                top: NOTIFICATION_TOP,
            })
            return createResponse
        } catch (e) {
            handleErrorMessage(e)
            throw e
        }
    }

    createPaypalOrder = async (payload) => {
        try {
            const createPaypalOrderResponse = await api.post('/events/create-paypal-order', payload, true)
            return createPaypalOrderResponse.jsonResponse.id
        } catch (e) {
            handleErrorMessage(e)
            throw e
        }
    }

    getStreamKey = async () => {
        try {
            const getStreamKeyResponse = await api.post('/events/stream')
            return getStreamKeyResponse
        } catch (e) {
            handleErrorMessage(e)
            throw e
        }
    }

    getEvent = async (eventId) => {
        try {
            const getEventResponse = await api.get(`/events/${eventId}`)
            this.setState({ ...getEventResponse })
            return getEventResponse
        } catch (e) {
            handleErrorMessage(e)
            throw e
        }
    }

    getEvents = async () => {
        try {
            const getEventsResponse = await api.get('/events')
            return getEventsResponse
        } catch (e) {
            handleErrorMessage(e)
            throw e
        }
    }

    handlePayPalApprove = async (payload) => {
        try {
            const handlePayPalApproveResponse = await api.post(
                '/events/handle-paypal-approve',
                payload,
                true
            )
            return handlePayPalApproveResponse
        } catch (e) {
            handleErrorMessage(e)
            throw e
        }
    }

    createPaymentIntent = async (payload) => {
        try {
            const createPaymentIntentResponse = await api.post(
                '/events/create-payment-intent',
                payload,
                true
            )
            return createPaymentIntentResponse
        } catch (e) {
            handleErrorMessage(e)
            throw e
        }
    }

    getPaymentIntent = async (clientSecret) => {
        try {
            const getPaymentIntentResponse = await api.get(
                `/events/get-payment-intent/${clientSecret}`
            )
            return getPaymentIntentResponse
        } catch (e) {
            handleErrorMessage(e)
            throw e
        }
    }

    getEventsHostedByUser = async ({ id }) => {
        try {
            const getEventsHostedByUser = await api.get(`/events/hosted-by-user/${id}`)
            return getEventsHostedByUser
        } catch (e) {
            handleErrorMessage(e)
            throw e
        }
    }

    sendInvites = async ({ invites, eventData, venueData }) => {
        try {
            const sendInvitesResponse = await api.post(
                `/events/send-invites`,
                {
                    invites,
                    eventData,
                    venueData,
                },
                true
            )
            return sendInvitesResponse
        } catch (e) {
            handleErrorMessage(e)
            throw e
        }
    }

    sendConfirmationEmails = async ({ eventId, userEmail }) => {
        try {
            const sendConfirmationEmailsResponse = await api.post(
                `/events/send-confirmation`,
                {
                    eventId,
                    userEmail,
                },
                true
            )
            return sendConfirmationEmailsResponse
        } catch (e) {
            handleErrorMessage(e)
            throw e
        }
    }

    sendReminderEmail = async ({ invites, eventData, venueData }) => {
        try {
            const sendReminderEmailResponse = await api.post(
                `/events/send-reminder`,
                {
                    eventData,
                    venueData,
                },
                true
            )
            return sendReminderEmailResponse
        } catch (e) {
            handleErrorMessage(e)
            throw e
        }
    }

    updateConfirmed = async ({ eventID, confirmedData, userEmail }) => {
        try {
            const updateConfirmedResponse = await api.post(
                `/events/confirmed`,
                {
                    eventID,
                    confirmedData,
                    userEmail,
                },
                true
            )
            return updateConfirmedResponse
        } catch (e) {
            handleErrorMessage(e)
            throw e
        }
    }

    updateEvent = async ({ eventId, eventParam }) => {
        try {
            const updateEventResponse = await api.put(`/events/${eventId}`, eventParam, true)
            let newState = this.state

            const _getNewTickets = () => {
                let index = this.state.tickets.findIndex(
                    (el) => el.paymentIntentId === eventParam.tickets[0].paymentIntentId
                )
                if (index === -1) {
                    return [...this.state.tickets, ...eventParam.tickets]
                }
                const newTickets = this.state.tickets.slice(0)
                newTickets[index] = eventParam.tickets[0]
                return newTickets
            }

            for (const [key, value] of Object.entries(eventParam)) {
                if (key === 'tickets') {
                    newState.tickets = _getNewTickets()
                } else {
                    newState[key] = value
                }
            }

            this.setState(newState)
            // notification['success']({
            //     message: 'You have successfully updated your information.',
            //     top: NOTIFICATION_TOP,
            // })
            return updateEventResponse
        } catch (e) {
            handleErrorMessage(e)
            throw e
        }
    }

    updateState = (state) => {
        this.setState(state)
    }

    render() {
        return (
            <Provider
                value={{
                    ...this.state,
                    cancelEvent: this.cancelEvent,
                    createEvent: this.createEvent,
                    getStreamKey: this.getStreamKey,
                    getEvent: this.getEvent,
                    getEvents: this.getEvents,
                    createPaymentIntent: this.createPaymentIntent,
                    createPaypalOrder: this.createPaypalOrder,
                    handlePayPalApprove: this.handlePayPalApprove,
                    getPaymentIntent: this.getPaymentIntent,
                    getEventsHostedByUser: this.getEventsHostedByUser,
                    sendInvites: this.sendInvites,
                    sendConfirmationEmails: this.sendConfirmationEmails,
                    sendReminderEmail: this.sendReminderEmail,
                    updateConfirmed: this.updateConfirmed,
                    updateEvent: this.updateEvent,
                    updateState: this.updateState,
                }}
            >
                {this.props.children}
            </Provider>
        )
    }
}

export { EventProvider, Consumer as EventConsumer }

export default EventContext
