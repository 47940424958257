/* eslint-disable no-extend-native */

if (!String.prototype.includes) {
    Object.defineProperty(String.prototype, 'includes', {
        value: function (search, start) {
            if (typeof start !== 'number') {
                start = 0
            }

            if (start + search.length > this.length) {
                return false
            } else {
                return this.indexOf(search, start) !== -1
            }
        },
    })
}

// https://tc39.github.io/ecma262/#sec-array.prototype.includes
if (!Array.prototype.includes) {
    Object.defineProperty(Array.prototype, 'includes', {
        value: function (searchElement, fromIndex) {
            if (this == null) {
                throw new TypeError('"this" is null or not defined')
            }

            // 1. Let O be ? ToObject(this value).
            var o = Object(this)

            // 2. Let len be ? ToLength(? Get(O, "length")).
            var len = o.length >>> 0

            // 3. If len is 0, return false.
            if (len === 0) {
                return false
            }

            // 4. Let n be ? ToInteger(fromIndex).
            //    (If fromIndex is undefined, this step produces the value 0.)
            var n = fromIndex | 0

            // 5. If n ≥ 0, then
            //  a. Let k be n.
            // 6. Else n < 0,
            //  a. Let k be len + n.
            //  b. If k < 0, let k be 0.
            var k = Math.max(n >= 0 ? n : len - Math.abs(n), 0)

            function sameValueZero(x, y) {
                return (
                    x === y ||
                    (typeof x === 'number' &&
                        typeof y === 'number' &&
                        isNaN(x) &&
                        isNaN(y))
                )
            }

            // 7. Repeat, while k < len
            while (k < len) {
                // a. Let elementK be the result of ? Get(O, ! ToString(k)).
                // b. If SameValueZero(searchElement, elementK) is true, return true.
                if (sameValueZero(o[k], searchElement)) {
                    return true
                }
                // c. Increase k by 1.
                k++
            }

            // 8. Return false
            return false
        },
    })
}

// https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Array/find
if (!Array.prototype.find) {
    Array.prototype.find = function (predicate) {
        if (this === null) {
            throw new TypeError(
                'Array.prototype.find called on null or undefined'
            )
        }
        if (typeof predicate !== 'function') {
            throw new TypeError('predicate must be a function')
        }
        var list = Object(this)
        var length = list.length >>> 0
        var thisArg = arguments[1]
        var value

        for (var i = 0; i < length; i++) {
            value = list[i]
            if (predicate.call(thisArg, value, i, list)) {
                return value
            }
        }
        return undefined
    }
}

if (!Array.prototype.fill) {
    Object.defineProperty(Array.prototype, 'fill', {
        value: function (value) {
            // Steps 1-2.
            if (this == null) {
                throw new TypeError('this is null or not defined')
            }

            var O = Object(this)

            // Steps 3-5.
            var len = O.length >>> 0

            // Steps 6-7.
            var start = arguments[1]
            var relativeStart = start >> 0

            // Step 8.
            var k =
                relativeStart < 0
                    ? Math.max(len + relativeStart, 0)
                    : Math.min(relativeStart, len)

            // Steps 9-10.
            var end = arguments[2]
            var relativeEnd = end === undefined ? len : end >> 0

            // Step 11.
            var final =
                relativeEnd < 0
                    ? Math.max(len + relativeEnd, 0)
                    : Math.min(relativeEnd, len)

            // Step 12.
            while (k < final) {
                O[k] = value
                k++
            }

            // Step 13.
            return O
        },
    })
}

var hasStoragePermission = (function () {
    try {
        localStorage.setItem('test', 'test')
        localStorage.removeItem('test')
        return true
    } catch (exception) {
        return false
    }
})()
if ('localStorage' in window && hasStoragePermission) {
    window.safeStorage = window.localStorage
} else {
    window.safeStorage = {
        _data: {},
        setItem: function (id, val) {
            return (this._data[id] = String(val))
        },
        getItem: function (id) {
            return this._data.hasOwnProperty(id) ? this._data[id] : undefined
        },
        removeItem: function (id) {
            return delete this._data[id]
        },
        clear: function () {
            return (this._data = {})
        },
    }
}
