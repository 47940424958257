import React, { Component } from 'react'
import { notification } from 'antd'
import api from 'utils/api'
import { handleErrorMessage } from 'utils/errorMessage'
import { NOTIFICATION_TOP } from '../constants/layout'
const VenueContext = React.createContext()
const { Provider, Consumer } = VenueContext

class VenueProvider extends Component {
    state = {}

    componentDidMount() {}

    createVenue = async (venueData) => {
        try {
            const createResponse = await api.post(
                '/venues/create',
                {
                    ...venueData,
                },
                true
            )
            notification['success']({
                message: 'You have successfully created your venue',
                top: NOTIFICATION_TOP,
            })
            return createResponse
        } catch (e) {
            handleErrorMessage(e)
            throw e
        }
    }

    getVenues = async () => {
        try {
            const getVenuesResponse = await api.get('/venues')
            return getVenuesResponse
        } catch (e) {
            handleErrorMessage(e)
            throw e
        }
    }

    getVenue = async (venueId) => {
        try {
            const getVenueResponse = await api.get(`/venues/${venueId}`)
            return getVenueResponse
        } catch (e) {
            handleErrorMessage(e)
            throw e
        }
    }

    getVenueBasic = async (venueId) => {
        try {
            const getVenueBasicResponse = await api.get(`/venues/${venueId}/basic`)
            return getVenueBasicResponse
        } catch (e) {
            handleErrorMessage(e)
            throw e
        }
    }

    updateState = (state) => {
        this.setState(state)
    }

    render() {
        return (
            <Provider
                value={{
                    ...this.state,
                    createVenue: this.createVenue,
                    getVenue: this.getVenue,
                    getVenueBasic: this.getVenueBasic,
                    getVenues: this.getVenues,
                    updateState: this.updateState,
                }}
            >
                {this.props.children}
            </Provider>
        )
    }
}

export { VenueProvider, Consumer as VenueConsumer }

export default VenueContext
