import React, { Component } from 'react'
// import api from 'utils/api'

const PlayerContext = React.createContext()
const { Provider, Consumer } = PlayerContext

class PlayerProvider extends Component {
    state = {
        players: [],
        currentIndex: 0,
    }

    componentDidMount() {}

    addPlayer = async (player) => {
        const { players } = this.state

        if (!players.includes(player)) {
            await this.setState({ players: [...players, player] })
        }
    }

    updateState = (obj) => {
        this.setState(obj)
    }

    render() {
        return (
            <Provider
                value={{
                    ...this.state,
                    updateState: this.updateState,
                    addPlayer: this.addPlayer,
                }}
            >
                {this.props.children}
            </Provider>
        )
    }
}

export { PlayerProvider, Consumer as AppConsumer }

export default PlayerContext
